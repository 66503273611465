import { createOwnUpComponent } from '@rategravity/own-up-component-library';

export const ValuePropsWrapper = createOwnUpComponent('div', {
  margin: '0 25px',
  '& > section > div > div': {
    gridTemplateColumns: '280px 280px 280px'
  },
  variants: {
    large: {
      '& div': {
        columnGap: '30px'
      }
    },
    medium: {
      '& > section > div > div': {
        gridTemplateColumns: '350px'
      }
    },
    small: {
      '& > section > div > div': {
        gridTemplateColumns: 'minmax(100%, 350px)'
      }
    },
    xsmall: {
      '& > section > div > div': {
        gridTemplateColumns: '240px',
        margin: 0
      }
    }
  }
});
