import { ValueProps } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { step1Ideas as ideas } from '../../../data/content/how-it-works';
import { metadata } from '../../../data/metadata/images';
import { ValuePropsWrapper } from '../../wrappers';
import { StepHeader } from '../step-header';

const TextWrapperStyle = createOwnUpStyle({
  textAlign: 'center'
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

export const Step1 = () => {
  return (
    <section aria-label="step 1">
      <TextWrapper>
        <StepHeader stepNumber={1}>Build your profile.</StepHeader>
        <ValuePropsWrapper>
          <ValueProps valueProps={ideas} metadata={metadata} />
        </ValuePropsWrapper>
      </TextWrapper>
    </section>
  );
};
