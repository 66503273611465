import { getImage, ImageQuery, ValueProps } from '@rategravity/marketing-components';
import { GetStartedLink } from '@rategravity/marketing-components';
import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import { step3Ideas as ideas } from '../../../data/content/how-it-works';
import { metadata } from '../../../data/metadata/images';
import { useMixpanel } from '../../../hooks/use-mixpanel';
import { CallToActionButton } from '../../button';
import { LinkWrapper } from '../../links';
import { ValuePropsWrapper } from '../../wrappers';
import { StepHeader } from '../step-header';

const SectionStyle = createOwnUpStyle({
  margin: '30px 0',
  variants: {
    mediumAndDown: {
      margin: '64px 0'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

export const imageStyle = {
  borderRadius: '50%',
  margin: 'auto',
  maxWidth: 250
};

// this is a fix for borderRadius in imageStyle not applying on safari
export const CircularImages = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    '& img': {
      borderRadius: '50%'
    }
  })
);
export const Ideas = ({ imageData }: { imageData: ImageQuery }) => {
  const step3Ideas = ideas.map(({ Image, data }) => {
    const img = getImage(imageData, Image.imageName, metadata, imageStyle);
    return {
      data,
      Image: img
    };
  });
  return (
    <ValuePropsWrapper>
      <CircularImages>
        <ValueProps
          valueProps={step3Ideas}
          metadata={metadata}
          header={
            <div style={{ textAlign: 'center', margin: '0 10px' }}>
              <StepHeader stepNumber={3}>Find the right loan for you.</StepHeader>
            </div>
          }
        />
      </CircularImages>
    </ValuePropsWrapper>
  );
};

export const Step3 = ({ children }: PropsWithChildren<{}>) => {
  const mixpanel = useMixpanel();

  return (
    <Section aria-label="step 3">
      {children}
      <LinkWrapper>
        <GetStartedLink
          discover={`${process.env.GATSBY_DISCOVER}/get-started`}
          mixpanelEvent={{
            hook: mixpanel,
            name: 'Click discover CTA',
            properties: { 'page-location': 'how-it-works-step3' }
          }}
        >
          <CallToActionButton>LET&apos;S GET STARTED</CallToActionButton>
        </GetStartedLink>
      </LinkWrapper>
    </Section>
  );
};
