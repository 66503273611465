import loadable from '@loadable/component';
import { ImageQuery, ImageWithContent } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import { Divider } from '../components/divider';
import { Step1 } from '../components/how-it-works/step-1';
import { Ideas } from '../components/how-it-works/step-3';
import { IntroImage, Text } from '../components/intro-image';
import { Layout } from '../components/layout';
import { Highlight, ResponsiveSectionHeader } from '../components/typography';
import GreenDotsBlob3SVG from '../images/green-dots-blob-3.svg';

const DotStrip = loadable(() => import('../components/dot-strip'), {
  resolveComponent: (components) => components.DotStrip
});

const KatinaImage = loadable(() => import('../components/how-it-works/step-2'), {
  resolveComponent: (components) => components.KatinaImage
});

const Step2 = loadable(() => import('../components/how-it-works/step-2'), {
  resolveComponent: (components) => components.Step2
});

const Step3 = loadable(() => import('../components/how-it-works/step-3'), {
  resolveComponent: (components) => components.Step3
});

const StepExistingOffer = loadable(() => import('../components/how-it-works/step-existing-offer'), {
  resolveComponent: (components) => components.StepExistingOffer
});

export const query = graphql`
  {
    images: allFile(
      filter: { relativePath: { regex: "/how-it-works//" }, extension: { ne: "svg" } }
    ) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

const HowItWorksIntroContent = () => (
  <>
    <ResponsiveSectionHeader variant="title">
      We&apos;re your <br />
      mortgage <Highlight>co-pilot</Highlight>.
    </ResponsiveSectionHeader>
    <Text>
      Own Up provides you with unbiased expertise that leads to better financial outcomes. Here’s
      how:
    </Text>
  </>
);

export const HowItWorks = ({ data }: { data: ImageQuery }) => {
  const HowItWorksIntroImage = <IntroImage imageData={data} imageName="tyler.jpg" />;
  const HowItWorksStep2Image = <KatinaImage imageData={data} />;

  return (
    <Layout>
      <ImageWithContent
        backgroundImage={GreenDotsBlob3SVG}
        Image={HowItWorksIntroImage}
        label="intro"
        sectionStyle={{ margin: '0 30px' }}
      >
        <HowItWorksIntroContent />
      </ImageWithContent>
      <Divider />
      <Step1 />
      <Divider />
      <Step2 Image={HowItWorksStep2Image} />
      <Divider />
      <Step3>{Ideas({ imageData: data })}</Step3>
      <Divider />
      <StepExistingOffer />
      <DotStrip />
    </Layout>
  );
};

export default HowItWorks;
