import React from 'react';
import { Catchphrase } from '../../../../components/typography';

const one = 'Pre-negotiated terms';
const two = 'You see what we see';
const three = 'With you every step';

export const step3Ideas = [
  {
    Image: {
      imageName: 'mike-bobblehead.jpg'
    },
    data: {
      catchphrase: <Catchphrase>{one}</Catchphrase>,
      text: 'We negotiate terms with our lender network so you can be confident in your offer.',
      label: one
    }
  },
  {
    Image: {
      imageName: 'mike-pointing-at-monitor.jpg'
    },
    data: {
      catchphrase: <Catchphrase>{two}</Catchphrase>,
      text: 'Our lenders pay us the same fee every time, so we show you every offer exactly as we see it.',
      label: two
    }
  },
  {
    Image: {
      imageName: 'brent.jpg'
    },
    data: {
      catchphrase: <Catchphrase>{three}</Catchphrase>,
      text: 'If you choose a lender in our network, we stay with you until closing to meet every obligation.',
      label: three
    }
  }
];
