import DocumentsSVG from '../../../../images/icons/documents.svg';
import LockedFolderSVG from '../../../../images/icons/locked-folder.svg';
import SafeSVG from '../../../../images/icons/safe.svg';

const one = 'Total security';
const two = 'Complete confidentiality';
const three = 'No SSN or hard credit inquiries';

export const step1Ideas = [
  {
    Image: {
      svg: SafeSVG,
      imageName: 'safe.svg',
      height: 144
    },
    data: {
      catchphrase: one,
      text: "Get your free personal profile in just 5 minutes. It's completely anonymous.",
      label: one
    }
  },
  {
    Image: {
      svg: LockedFolderSVG,
      imageName: 'locked-folder.svg',
      height: 144
    },
    data: {
      catchphrase: two,
      text: 'Your personal information is never sold and there is no obligation.',
      label: two
    }
  },
  {
    Image: {
      svg: DocumentsSVG,
      imageName: 'documents.svg',
      height: 144
    },
    data: {
      catchphrase: three,
      text: 'We deliver personalized loan offers without requiring a SSN. We only do a soft inquiry.',
      label: three
    }
  }
];
