import React, { PropsWithChildren } from 'react';
import { Highlight, ResponsiveSectionHeader } from '../../typography';

export const StepHeader = ({
  children,
  stepNumber
}: PropsWithChildren<{
  stepNumber: number;
}>) => (
  <ResponsiveSectionHeader>
    <Highlight>Step {stepNumber}:</Highlight>
    <br />
    {children}
  </ResponsiveSectionHeader>
);
