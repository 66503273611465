import React from 'react';
import { Catchphrase } from '../../../../components/typography';
import ConversationBubblesSVG from '../../../../images/icons/conversation-bubbles.svg';
import DocumentsWithArrowsSVG from '../../../../images/icons/documents-with-arrows.svg';

const one = 'Compare offers';
const two = 'Get better terms';

export const stepExistingOfferIdeas = [
  {
    Image: {
      svg: DocumentsWithArrowsSVG,
      imageName: 'documents-with-arrows.svg'
    },
    data: {
      catchphrase: <Catchphrase>{one}</Catchphrase>,
      text: 'See how your loan offer compares to actual pre-qualified loan offers from the top-rated lenders in our network.',
      label: one
    }
  },
  {
    Image: {
      svg: ConversationBubblesSVG,
      imageName: 'conversation-bubbles.svg'
    },
    data: {
      catchphrase: <Catchphrase>{two}</Catchphrase>,
      text: 'We can help you negotiate better terms with your lender, saving you thousands.',
      label: two
    }
  }
];
