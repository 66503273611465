import React from 'react';
import { Catchphrase } from '../../../../components/typography';
import LetterSVG from '../../../../images/icons/letter.svg';
import MobileDeviceSVG from '../../../../images/icons/mobile-device.svg';
import WebsiteSVG from '../../../../images/icons/website.svg';

const one = '7-Minute Call';
const two = 'Our Insight Tools';
const three = 'Pre-Approval Letter Generator';

export const step2Ideas = [
  {
    Image: {
      svg: MobileDeviceSVG,
      imageName: 'mobile-device.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{one}</Catchphrase>,
      text: "We discuss your goals and what to expect during the process — it's not a sales call.",
      label: one
    }
  },
  {
    Image: {
      svg: WebsiteSVG,
      imageName: 'website.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{two}</Catchphrase>,
      text: 'See what you qualify for, compare lenders, and see actual pre-qualified rates.',
      label: two
    }
  },
  {
    Image: {
      svg: LetterSVG,
      imageName: 'letter.svg',
      height: 144
    },
    data: {
      catchphrase: <Catchphrase>{three}</Catchphrase>,
      text: "We have the industry's first automated pre-approval letter — generate updates on demand from any device.",
      label: three
    }
  }
];
